import React, { Component } from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import IndexJumbotron from '../components/page-sections/index/indexJumbotron'
import FixYourFlashRow from '../components/page-sections/index/fixYourFlashRow'
import ScheduleConsultation from '../components/page-sections/schedule-consultation'

import './index.scss'


const IndexPage = class extends Component {
  render() {
    const { location } = this.props
    return (
      <React.Fragment>
        <Layout location={location}>
          <SEO title="G Jones for Hormones" />
          <IndexJumbotron />
          <Container>
            <Row style={{ marginBottom: '2rem' }}>
              <Col>
                <h2>What is happening to me?</h2>
                <p>
                  A woman is born with ovaries that already contain the oocytes (eggs) that are released monthly during reproductive years. As the oocyte numbers diminish over many cycles, the ovaries stop producing estradiol. A surge in FSH (follicle stimulating hormone) and LH (luteinizing hormone), triggered by the pituitary gland, due to the ovaries not producing enough estradiol is the beginning of the need for estrogen balance needed for good health.
                </p>
                <p>
                  Many health issues from different body systems can arise when one is out of balance. Cyclic replacement therapy gets your body back to a youthful, healthy state.
                </p>
                <p>
                  Systems similar to menopause can also occur after removal of ovaries and/or the uterus, response to radiation and chemotherapy treatments, reaction to particular medication(s), genetic factors, or an unhealthy diet.
                </p>
                <p>
                  <strong>Heart disease and Stroke.</strong> Estrogen has a benefit for helping blood vessels stay healthy and strong. This will allow the blood to move through the circulatory system and maintain a healthy balance between good and bad cholesterol. As the ovaries produce less estrogen in the body, the walls of the blood vessels collect lipid deposits and the risk for heart disease increases.
                </p>
                <p>
                  <strong>Osteoporosis.</strong> Estrogen helps keep the bones from becoming brittle and keeps them healthy. As estrogen levels decrease the risk for osteoporosis and bone/hip fractures increases.
                </p>
                <p>
                  <strong>Urinary incontinence.</strong> Estrogen helps the walls of the urethra stay healthy. When estrogen levels decrease the walls of the urethra weaken, and embarrassing incontinence can occur.
                </p>
                <p>
                  <strong>Bad Breath.</strong> Oral health can be affected by low levels of estrogen causing cavities and gingivitis.
                </p>
              </Col>
            </Row>
          </Container>
          <FixYourFlashRow />
          <ScheduleConsultation />
        </Layout>
      </React.Fragment>
    )
  }
}

export default IndexPage
