import React, { Component } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

// import Button from 'react-bootstrap/Button'
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'

import './fixYourFlashRow.scss'


export default class FixYourFlashRow extends Component {
  constructor(props) {
    super(props)
    this.state = {
      counter: 1,
      currentWord: "Let's Fix Your FLASH!",
      wordsList: [
        "Let's Fix Your FLASH!",
        "Let's Fix Your NIGHT SWEATS!",
        "Let's Fix Your IRRITABILITY!",
        "Let's Fix Your LIBIDO!",
        "Let's Fix Your HAIR LOSS!",
        "Let's Fix Your FOCUS!",
        "Let's Fix Your LETHARGY!"
      ],
      // fadingIn: false,
      // fadingOut: false,
    }
  }

  componentDidMount() {
    // console.log(this.state.counter)
    this.interval = setInterval(() => {
      // this.setState({ fadingOut: true })
      if ( this.state.counter === 6 ) {
        this.setState({
          counter: 0,
          currentWord: this.state.wordsList[this.state.counter],
          // fadingOut: false,
          // fadingIn: true,
        })
        // console.log(this.state.wordsList[this.state.counter])
      } else {
        this.setState({
          counter: this.state.counter + 1,
          currentWord: this.state.wordsList[this.state.counter],
          // fadingOut: false,
          // fadingIn: true,
        })
        // console.log(this.state.wordsList[this.state.counter])
        // console.log(this.state.counter)
      }
    }, 4000)
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            desktop: file(relativePath: { eq: "index/young-woman-training-yoga-outdoor-in-autumn-or-fall-park.jpg" }) {
              childImageSharp {
                fluid(quality: 80, maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => {
          // Set ImageData.
          const imageData = data.desktop.childImageSharp.fluid
          return (
            <BackgroundImage
              Tag="section"
              className="fixYourFlashRow"
              fluid={imageData}
              style={{ borderRadius: '0' }}
            >
              <Img
                className="hidden"
                fluid={imageData}
                alt="Young woman training yoga outdoor in autumn or fall park."
              />
              <div
                className='m-auto'
                style={{
                  // backgroundColor: 'rgba(255, 192, 203, .65)',
                  // borderRadius: '0.3rem',
                  padding: '5rem 0',
                  maxWidth: '75%'
                }}
              >
                <h2 className="text-center display-3" style={{ color: 'white', fontWeight: '400' }}>
                  {
                    this.state.fadingIn
                    ?
                    <span className="fadeInDown">{this.state.currentWord}</span>
                    :
                    this.state.fadingOut
                    ?
                    <span className="fadeInDown">{this.state.currentWord}</span>
                    :
                    <span>{this.state.currentWord}</span>
                  }
                </h2>
              </div>
            </BackgroundImage>
          )
        }}
      />
    )
  }
}
