import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

import Button from 'react-bootstrap/Button'

import './indexJumbotron.scss'


const IndexJumbotron = () => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "index/happy-healthy-middle-aged-woman-sitting-in-wicker-chair-outside-enjoying-a-beautiful-day.jpg" }) {
          childImageSharp {
            fluid(quality: 80, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className="jumbotron indexJumbotron"
          fluid={imageData}
          style={{ borderRadius: '0' }}
        >
          <Img
            className="hidden"
            fluid={imageData}
            alt="Happy, healthy middle aged woman sitting in wicker chair outside enjoying a beautiful day."
          />
          <div className="indexJumbotronContent">
            <h1 className="indexJumbotronHeading">Rebalance Your Hormones With Cyclic Replacement Therapy (CRT)</h1>
            <p>
              Our mission is to help you find your sacred hormonal balance with the right combination of hormones applied transdermally to the skin.
            </p>
            <p>
              <Link to="/rhythmic-dosing/">
                <Button variant="primary">Learn more</Button>
              </Link>
            </p>
          </div>
        </BackgroundImage>
      )
    }}
  />
)

export default IndexJumbotron
